import React from "react"
import Layout from "../../components/layout"
import SEO from "../../components/seo"
import { Link } from "gatsby"

export default () => (
  <Layout>
    <SEO
      title="Automation Engineer - Careers at D2i Technology"
      description="Join our team as an Automation Engineer. 4+ years of experience required in OTT platforms, network validation tools, video player testing, and test automation using Selenium and Appium."
    />
    <section id="career-detail" className="career-detail">
      <div className="container" data-aos-todo="fade-up">
        <div className="section-title">
          <h1>Automation Engineer</h1>
        </div>
        <div className="row mt-5">
          <div className="col-lg-8 mx-auto">
            <nav aria-label="breadcrumb">
              <ol className="breadcrumb">
                <li className="breadcrumb-item">
                  <Link to="/careers">Careers</Link>
                </li>
                <li className="breadcrumb-item active" aria-current="page">
                  Automation Engineer
                </li>
              </ol>
            </nav>
            <div className="job-card">
              <div className="job-header">
                <h2>Job Description</h2>
                <Link
                  to="https://forms.gle/mCYwx55YhT6vA1cJ7"
                  target="_blank"
                  className="btn btn-primary"
                >
                  Apply Now
                </Link>
              </div>
              <p>
                We are seeking an experienced Automation Engineer to join our
                quality assurance team. The ideal candidate will have a strong
                background in OTT platforms, network validation tools, and video
                player testing, with expertise in test automation using Selenium
                and Appium.
              </p>

              <h3>Requirements:</h3>
              <ul>
                <li>
                  4+ years of experience in software testing and automation
                </li>
                <li>
                  Proficiency in OTT (Over-The-Top) platforms and video player
                  testing
                </li>
                <li>Strong knowledge of network validation tools</li>
                <li>Expertise in test automation using Selenium and Appium</li>
                <li>Experience in writing and executing test cases</li>
                <li>
                  Familiarity with continuous integration and continuous
                  deployment (CI/CD) pipelines
                </li>
                <li>Strong analytical and problem-solving skills</li>
                <li>Excellent communication and teamwork abilities</li>
              </ul>

              <h3>Responsibilities:</h3>
              <ul>
                <li>
                  Design, develop, and maintain automated test suites for web
                  and mobile applications
                </li>
                <li>
                  Perform comprehensive testing of OTT platforms and video
                  players
                </li>
                <li>
                  Create and execute test cases for functional and
                  non-functional requirements
                </li>
                <li>
                  Utilize network validation tools to ensure application
                  performance and reliability
                </li>
                <li>
                  Collaborate with development teams to improve software quality
                  and testing processes
                </li>
                <li>
                  Identify, report, and track software defects using bug
                  tracking tools
                </li>
                <li>
                  Participate in code reviews and contribute to improving
                  overall code quality
                </li>
                <li>
                  Stay up-to-date with the latest trends and best practices in
                  test automation
                </li>
              </ul>

              <h3>What We Offer:</h3>
              <ul>
                <li>Competitive salary commensurate with experience</li>
                <li>
                  Opportunity to work on cutting-edge OTT and video streaming
                  technologies
                </li>
                <li>Professional development and learning opportunities</li>
                <li>Flexible work environment and work-life balance</li>
                <li>Collaborative and innovative team culture</li>
              </ul>
            </div>
            <div className="apply-footer">
              <p>Ready to revolutionize our testing processes?</p>
              <Link
                to="https://forms.gle/mCYwx55YhT6vA1cJ7"
                target="_blank"
                className="btn btn-primary"
              >
                Apply for this Position
              </Link>
            </div>
          </div>
        </div>
      </div>
    </section>
  </Layout>
)
